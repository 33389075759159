import React, { useCallback, useContext, useMemo, useState } from "react";
import { wishlistItemCount } from "../../Contexts/WishListContext";
import Selectoptions from "../Selectoptions";
import {
  minNumberOfOrdersAllowedToGetOwnerDetails,
  pricesForRentalDetails,
  propertyTypes,
} from "../../constants/pricingDetails";
import axios from "axios";
import WhatappAgreementPopUp from "./WhatappAgreementPopUp";
import { useNavigate } from "react-router-dom";
import {
  getKeyByValueFromObj,
  countRepeatingItems,
} from "../../functions/helperFunction";

const ResidentTypes = ["Family", "Bachelor"];

async function sendWhatsappInitializationMsg(contactNumber) {
  const url = "https://bookmyqrcode.com/home_rental/api/api.php";
  const dataToSend = {
    user_number: contactNumber,
    method: "sendInitialMessage",
  };
  axios
    .post(url, dataToSend)
    .then((res) => {
      console.log("Whatsapp initialization msg sent successfuly: ", res);
    })
    .catch((err) => {
      console.log("failed to send Whatsapp initialization msg", err);
    });
}

async function checkIfUserIsNew(contactNumber) {
  const url = "https://bookmyqrcode.com/home_rental/api/api.php";
  const data = {
    user_number: contactNumber,
    method: "checkToSendMessegeOrNot",
  };
  try {
    const res = await axios.post(url, data);
    // console.log(res.data);
    // console.log(res.data.exists);
    return res.data;
  } catch (error) {
    console.log("failed to fetch if user is new: ", error);
    return null;
  }
}

const getKeyByValue = getKeyByValueFromObj(propertyTypes);

const initialStateOfFormData = {
  name: "",
  number: "",
  workPlace: "",
};

function PaymentForm() {
  const navigate = useNavigate();
  const { data } = useContext(wishlistItemCount);
  const [showWhatsappAgreementPopUP, setShowWhatsappAgreementPopUP] =
    useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [residentType, setResidentType] = useState(null);
  // const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState(initialStateOfFormData);

  const itemsFromWishlist = useMemo(() => Array.from(data), [data]);

  const orderSummery = useMemo(() => {
    const categories = itemsFromWishlist.map((item) => Number(item.categoryId));
    const totalAmount = data.size >= 5
    ? categories.reduce((acc, currentValue) => {
        return acc + pricesForRentalDetails[currentValue];
      }, 0)
    : pricesForRentalDetails[Math.max(...categories)] * 5;
    return { totalAmount, categories };
  }, [data, pricesForRentalDetails]);

  const sendPaymentDetails = useCallback(async (userNumber, entities) => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          user_number: userNumber,
          entities: entities,
          method: "sendUserBrokerDetailsOnPayment",
        }
      );

      console.log("Message sent successfully:", response);
      navigate("/brokerOwnerDetails", { state: response.data.details });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }, []);

  const handleFormChange = useCallback(
    (e) => {
      const formField = e.target.getAttribute("data-formField");
      // console.log(formField);
      setFormData((prevState) => ({
        ...prevState,
        [formField]: e.target.value,
      }));
    },
    [setFormData]
  );

  // const handleCheckboxChange = useCallback(() => {
  //   setIsChecked((prevState) => !prevState);
  // }, []);

  const isValidIndianPhoneNumber = useCallback((number) => {
    // Remove any spaces, hyphens or other characters
    const cleanNumber = number.replace(/\D/g, "");

    // Pattern for Indian mobile numbers:
    // 1. Should be 10 digits
    // 2. Can optionally start with +91 or 91
    // 3. First digit should be 6-9
    const pattern = /^(?:(?:\+|91)?[6-9]\d{9})$/;

    return pattern.test(cleanNumber);
  }, []);

  const checkInValidFields = useCallback(() => {
    setInvalidFields([]); //so that the previous data gets flushed out
    // checks if any of the name, number or workplace is not valid
    let invalidFieldsArr = [];
    Object.keys(formData).forEach((formField) => {
      if (formField != "number" && formData[formField].trim().length == 0) {
        invalidFieldsArr = [...invalidFieldsArr, formField];
      }
      if (formField == "number" && !isValidIndianPhoneNumber(formData.number)) {
        invalidFieldsArr = [...invalidFieldsArr, formField];
      }
    });
    //checks if residenttype is not null
    if (!residentType) {
      invalidFieldsArr = [...invalidFieldsArr, "residentType"];
    }
    setInvalidFields((prevState) => [...prevState, ...invalidFieldsArr]);
    return invalidFieldsArr;
  }, [formData, residentType]);

  const generateEntities = useCallback(() => {
    const entities = itemsFromWishlist.map((item) => {
      const {
        area_name,
        city_name,
        brokerId,
        ownerId,
        categoryId,
        cityId,
        areaId,
        rent,
      } = item;
      const defaultParams = {
        City: city_name,
        area: area_name,
        title: getKeyByValue(categoryId),
        brokerid: Number(brokerId),
        cityId,
        areaId,
        categoryId,
        rent,
        name: formData.name,
        number: formData.number,
        residentType,
        workPlace: formData.workPlace,
      };
      if (brokerId) {
        return {
          brokerid: Number(brokerId),
          ...defaultParams,
        };
      } else {
        return {
          ownerId: Number(ownerId),
          ...defaultParams,
        };
      }
    });
    return entities;
  }, [itemsFromWishlist, formData, residentType]);

  const sendMsgAndShowBrokerDetailsUI = useCallback(() => {
    const entitiesToSend = generateEntities();
    sendPaymentDetails(formData.number, entitiesToSend);
  }, [formData.number, generateEntities]);

  const togglePopUp = useCallback(async () => {
    const checkIfUserIsNewOrOld = await checkIfUserIsNew(formData.number);
    if (checkIfUserIsNewOrOld) {
      const existance = checkIfUserIsNewOrOld.exists;
      // console.log("existance: ", existance);
      if (existance) {
        sendMsgAndShowBrokerDetailsUI();
      } else {
        sendWhatsappInitializationMsg(formData.number);
        setShowWhatsappAgreementPopUP(true);
      }
    } else {
      console.log("failed to fetch checkIfUserIsNew()");
    }
  }, [setShowWhatsappAgreementPopUP, checkInValidFields, formData]);

  //function to load razorpay sript in frontend
  function loadRazorPay() {
    if (invalidFields.length == 0) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
      script.onload = () => {
        var Razorpay = window.Razorpay;
        handlePayment(Razorpay);
      };
    }
  }

  async function handlePayment(Razorpay) {
    const amount = orderSummery.totalAmount;
    try {
      const response = await fetch(
        `https://bookmyqrcode.com/home_rental/razorpay/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount: amount }),
        }
      );

      const result = await response.json();
      console.log("Success:", result);
      if (response.ok) {
        var options = {
          key_id: "rzp_test_w1PnHafmCNsrDy",
          amount: `${result.amount}`,
          currency: "INR",
          name: "Acme Corp",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: `${result.id}`,
          handler: function (response) {
            console.log("Payment successfull!");
            togglePopUp();
          },
          prefill: {
            name: formData.name,
            contact: formData.number,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        // console.log(options);

        var rzp1 = new Razorpay(options);
        rzp1.open();

        rzp1.on("payment.failed", function (response) {
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
      }

      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const checkFormFieldValidity = checkInValidFields();
    if (checkFormFieldValidity.length == 0) {
      if (orderSummery.totalAmount > 0) {
        loadRazorPay();
      } else {
        togglePopUp();
      }
    }
  }

  // console.log(residentType);

  return (
    <>
      {showWhatsappAgreementPopUP && (
        <div
          className="position-fixed top-50 start-50"
          style={{ transform: "translate(-50%, -50%)", zIndex: 10 }}
        >
          <WhatappAgreementPopUp
            sendMsgAndShowBrokerDetailsUI={sendMsgAndShowBrokerDetailsUI}
            setShowWhatsappAgreementPopUP={setShowWhatsappAgreementPopUP}
          />
        </div>
      )}
      <form
        className="bg-white shadow d-flex gap-4 align-items-center flex-column"
        style={{
          maxWidth: "554px",
          minWidth: "333px",
          height: "fit-content",
          padding: "1.5rem",
          borderRadius: 19.29,
          zIndex: 10
        }}
      >
        {/* <div
          className="py-2 row gap-2 align-items-stretch justify-content-stretch bg-danger w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="position-relative">
            {invalidFields.includes("residentType") && (
              <p
                className="text-danger position-absolute w-100 left-2"
                style={{ fontSize: 12, top: -20 }}
              >
                Please select a residentType
              </p>
            )}
            <Selectoptions
              placeholder="Resident Type"
              options={ResidentTypes}
              residentType={residentType}
              setResidentType={setResidentType}
            />
          </div>
        </div> */}
        <div className="position-relative col-12">
          {invalidFields.includes("name") && (
            <p
              className="text-danger position-absolute w-100 left-2"
              style={{ fontSize: 12, top: -20 }}
            >
              Please enter a valid name
            </p>
          )}
          <input
            type="text"
            placeholder="Name"
            data-formField="name"
            className={`border-2 ${
              invalidFields.includes("name")
                ? "border-danger"
                : "border-grey"
            } p-2 rounded-2 w-100 h-100`}
            value={formData.name}
            onChange={handleFormChange}
            style={{ outline: "none" }}
            required
          />
        </div>
        <div className="position-relative col-12">
          {invalidFields.includes("number") && (
            <p
              className="text-danger position-absolute w-100 left-2"
              style={{ fontSize: 12, top: -20 }}
            >
              Please enter a valid number
            </p>
          )}
          <input
            type="number"
            placeholder="WhatsApp Number"
            data-formField="number"
            className={`border-2 ${
              invalidFields.includes("number")
                ? "border-danger"
                : "border-grey"
            } p-2 rounded-2 w-100`}
            value={formData.number}
            onChange={handleFormChange}
            style={{ outline: "none" }}
            required
          />
        </div>
        <div className="position-relative col-12">
          {invalidFields.includes("workPlace") && (
            <p
              className="text-danger position-absolute w-100 left-2"
              style={{ fontSize: 12, top: -20 }}
            >
              Please enter a valid workPlace
            </p>
          )}
          <input
            type="text"
            placeholder="Company Name"
            className={`border-2 ${
              invalidFields.includes("workPlace")
                ? "border-danger"
                : "border-grey"
            } p-2 rounded-2 w-100 h-100`}
            data-formField="workPlace"
            value={formData.workPlace}
            onChange={handleFormChange}
            style={{ outline: "none" }}
            required
          />
        </div>
        <div className="d-flex justify-content-between col-12 fs-5 align-items-center position-relative gap-2">
          {invalidFields.includes("residentType") && (
            <p
              className="text-danger position-absolute w-100 left-2"
              style={{ fontSize: 12, top: -20 }}
            >
              Please select a residentType
            </p>
          )}
          <div className="fw-bold" style={{whiteSpace: "nowrap"}}>Resident Type:</div>
          <div className="d-flex gap-2 align-items-center flex-wrap">
            <label
              htmlFor="bachlor"
              className="d-flex gap-2 radio-button text-black"
            >
              <input
                type="radio"
                name="residentType"
                value="bachlor"
                id="bachlor"
                checked={residentType == "bachlor"}
                onChange={(e) => setResidentType(e.target.value)}
              />
              <span class="radio"></span>{" "}
              {/* used for custome styling dont remove */}
              Bachelor
            </label>
            <label
              htmlFor="family"
              className="d-flex gap-2 radio-button text-black"
            >
              <input
                type="radio"
                name="residentType"
                value="family"
                id="family"
                checked={residentType == "family"}
                onChange={(e) => setResidentType(e.target.value)}
              />
              <span class="radio"></span>{" "}
              {/* used for custome styling dont remove */}
              Family
            </label>
          </div>
        </div>
        {/* <div className="border-bottom w-100">
          <p className="d-flex justify-content-between fw-bolder">
            <span>Total interests</span> <span>{data.size}</span>
          </p>
          <hr />
          <div>
            <p className="fw-bold text-center" role="heading">
              Orders
            </p>
            <div>{orderDetails}</div>
          </div>
        </div> */}

        {/* <p className="d-flex justify-content-between w-100 fw-bolder">
          <span>To Pay</span>{" "}
          <span className="text-success" style={{ letterSpacing: 2 }}>
            ₹{orderSummery.totalAmount}
          </span>
        </p> */}
        {/* <div className="d-flex align-items-center gap-3">
          <div
            className="px-1 d-flex justify-content-center align-items-center rounded cursor-pointer"
            onClick={handleCheckboxChange}
            style={{
              width: 35,
              backgroundColor: isChecked ? "red" : "white",
              border: "2px solid red",
              aspectRatio: 1,
            }}
          >
            {isChecked && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="8"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-check"
              >
                <path d="M20 6 9 17l-5-5" />
              </svg>
            )}
          </div>
          <p className="mt-2">
            To assist in your property search, may I have your permission to
            share your details with relevant parties.
          </p>
        </div> */}
        {/* <p className="text-danger bg-warning bg-opacity-10 p-3 w-100 rounded-2">
          <span className="fw-bold">Note:</span> You are allowing us to share
          your details with relevant parties
        </p> */}
        <div className="paymentBtnPostion">
          <button
            className="btn btn-danger d-flex justify-content-center w-100 p-2 gap-2 rounded-pill align-items-center position-relative"
            // disabled={data.size < minNumberOfOrdersAllowedToGetOwnerDetails}
            onClick={handleSubmit}
            style={{ maxWidth: "90vw", bottom: 16 }}
          >
            {/* {data.size < minNumberOfOrdersAllowedToGetOwnerDetails && (
              <p
                className="text-danger position-absolute"
                style={{ top: "-50%" }}
              >
                Add {minNumberOfOrdersAllowedToGetOwnerDetails - data.size} more
                to get owners details.
              </p>
            )} */}
            <div className="p-2 rounded-circle bg-white d-md-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="red"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-move-right"
              >
                <path d="M18 8L22 12L18 16" />
                <path d="M2 12H22" />
              </svg>
            </div>
            <div className="w-100 fs-5">
              {orderSummery.totalAmount > 0 ? (
                <>Proceed to Payment | ₹{orderSummery.totalAmount}</>
              ) : (
                <>Get details for Free/-</>
              )}
            </div>
          </button>
        </div>
      </form>
    </>
  );
}

export default PaymentForm;
