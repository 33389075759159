import PaymentHeader from "./PaymentHeader";
import PaymentContent from "./PaymentContent";
import OtherCourseCard from "./OtherCourseCard";
import { useCallback, useContext, useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import { wishlistItemCount } from "../../Contexts/WishListContext";
import { Filter } from "../../Contexts/FilterContext";
import CartModel from "../CartModel";
import { Link } from "react-router-dom";
import Suggestions from "./Suggestions";
import OrderDetails from "./OrderDetails";

function CartPage() {
  const { data } = useContext(wishlistItemCount);
  const { videoFilterData } = useContext(Filter);
  const [otherRentals, setOtherRentals] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [listCategory, setListCategory] = useState([]); //tell which list to be scrolled within the model (otherRental or wishlist)
  const [selectedItem, setSeletedItem] = useState(null);

  const toggleModel = useCallback(
    (e) => {
      const idAndCategory = e.currentTarget.id.split(" ");
      setOpenModel((prevState) => !prevState);
      // console.log(idAndCategory);
      setSeletedItem(idAndCategory[0]);
      idAndCategory[1] == "wishlist"
        ? setListCategory(data)
        : setListCategory(otherRentals);
    },

    [data, otherRentals]
  );
  useEffect(() => {
    console.log("datachanged");
    const ids = Array.from(data).map((item) => item.inventroyId);
    // console.log(ids);
    const recomendations = videoFilterData.filter((item) => {
      // console.log(item.inventroyId, !ids.includes(item.inventroyId));
      return !ids.includes(item.inventroyId);
    });
    setOtherRentals((prevstate) => {
      // console.log("previsou recomendations: ", prevstate);
      // console.log("new recomendations: ", recomendations);
      return recomendations;
    });
  }, [videoFilterData, data]);
  // console.log(otherRentals);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="md-h-100dvh"
      style={{ paddingBottom: 90 }}
    >
      {openModel && (
        <CartModel
          item={selectedItem}
          itemCategory={listCategory}
          setOpenModel={setOpenModel}
        />
      )}
      <div
        className="d-flex flex-column gap-4 overflow-md-hidden position-relative overflow-visible"
      >
        <PaymentHeader />
        <div style={{ flex: 1, maxWidth: 1440, width: "90vw", margin: "auto" }}>
          <div className="row g-4 d-flex align-items-center h-100">
            <div
              className={`${
                data.size > 0 ? "col-md-6" : "col-md-12"
              } col-sm-12 d-flex flex-column order-md-1 order-2 gap-2`}
            >
              {data && data.size > 0 && (
                <div className="fs-4 fw-semibold">In Cart:</div>
              )}
              <div
                className="shadow-sm rounded p-3 d-flex flex-column"
                id="wishlist"
                style={data && data.size > 0 ?{
                  height: 250,
                  overflowY: "auto",
                  backgroundColor:"white",
                }: {
                  minHeight: 250,
                  height: "100%",
                  backgroundColor:"lightgrey",
                }}
              >

                {data && data.size > 0 ? (
                  Array.from(data).map((item, index) => (
                    <PaymentContent
                      id={`${index} wishlist`}
                      onClick={toggleModel}
                      key={item.inventroyId}
                      Details={item.title}
                    />
                  ))
                ) : (
                  <div
                    className="text-secondary d-flex justify-content-center align-items-center text-center flex-column gap-1"
                    style={{ flex: 1, minHeight: "fit-content" }}
                  >
                    <div style={{ fontSize: "clamp(24px, 8vw, 42px)" }}>
                      Your RentOut Homes Cart is Empty.
                    </div>
                    <span className="h4">Add properties to cart for getting details.</span>
                    <p>
                      Check other Rents below or{" "}
                      <Link to="/">Continue browsing</Link>
                    </p>
                  </div>
                )}
              </div>
              <Suggestions
                otherRentals={otherRentals}
                toggleModel={toggleModel}
              />
            </div>
            {data.size > 0 && (
              <div className="col-md-6 d-flex flex-column gap-3 justify-content-stretch align-items-center order-md-2 order-1">
                <PaymentForm recomendations={otherRentals} />
                <div
                  className="order-1 w-100"
                  style={{ maxWidth: "554px", minWidth: "333px" }}
                >
                  <OrderDetails />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartPage;
