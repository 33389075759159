import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const PopupPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const location = useLocation();
  const [id, setId] = useState(null);

  useEffect(() => {
    const lastSubmissionTime = localStorage.getItem("lastSubmissionTime");
    if (lastSubmissionTime) {
      const currentTime = new Date().getTime();
      const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; 
      if (currentTime - lastSubmissionTime < oneWeekInMilliseconds) {
        setShowPopup(false); 
      }
    }

    const params = new URLSearchParams(location.search);
    const idFromUrl = params.get("id");
    if (idFromUrl) {
      setId(idFromUrl);
    }
  }, [location]);

  const handleSubmit = async () => {
    if (phoneNumber.length !== 10) {
      toast.error("Enter a valid 10-digit phone number", {
        position: "bottom-right",
        autoClose: 4988,
        theme: "dark",
      });
      return;
    }

    try {
      const apiResponse = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "setData",
          table: "reach_us",
          data: {
            name: "",
            mobileNumber: phoneNumber,
            email: "",
            subject: "Phone number submission",
            message: `This request is from Pop up with ID: ${id || "No ID available"}`,
          },
        }
      );

      if (apiResponse.data.status === "Success") {
        toast.success("Successfully submitted", {
          position: "bottom-right",
          autoClose: 4988,
          theme: "dark",
        });
        
        // Save the current time as the last submission time
        localStorage.setItem("lastSubmissionTime", new Date().getTime());

        setPhoneNumber(""); // Clear phone number input
        setShowPopup(false); // Close the modal
      } else {
        toast.error("Submission failed", {
          position: "bottom-right",
          autoClose: 4988,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("An error occurred while submitting", {
        position: "bottom-right",
        autoClose: 4988,
        theme: "dark",
      });
    }
  };

  return (
    <>
      {showPopup && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(39, 38, 38, 0.91)", // Semi-transparent black
            zIndex: 10,
          }}
        />
      )}

      <div
        style={{
          position: "absolute",
          zIndex: "9999",
          top: "40%",
          width: "100%",
          left: "0",
          padding: "0px 30px",
        }}
      >
        {showPopup && (
          <div
            style={{
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              width: "100%",
              backgroundColor: "whitesmoke",
              animation: "fadeUp 0.5s ease-in-out",
            }}
          >
            <h6 style={{ color: "black", marginBottom: "20px" }}>
              To watch video enter Your Phone Number
            </h6>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter your phone number"
              style={{
                width: "100%",
                marginBottom: "20px",
                borderRadius: "5px",
                padding: "5px 15px",
                fontSize: "15px",
                border: "1px solid gray",
              }}
            />
            <button
              onClick={handleSubmit}
              style={{
                padding: "5px 30px",
                borderRadius: "8px",
                backgroundColor: phoneNumber ? "green" : "gray",
                color: "white",
                cursor: phoneNumber ? "pointer" : "not-allowed",
                border: "none",
                fontSize: "13px",
              }}
              disabled={!phoneNumber}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PopupPage;
